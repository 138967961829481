import { faBookmark, faComment } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faFacebook, faTwitter, faWhatsapp, faLinkedin } from '@fortawesome/free-brands-svg-icons';


const DataRepoCustomSolution = () => {
  return (
    <div className="bg-[#fff] w-full flex flex-col">
      <section
        style={{ backgroundImage: `url('../images/custom-software-solutions.png')`, backgroundSize: "100% 60vh" }}
        className="hero--container relative flex flex-col justify-center w-full h-[60vh] bg-no-repeat bg-fixed md:bg-origin-border pt-[65px]">
        <p className="text-white p-1.5 rounded-md text-xs font-bold text-center mb-10 bg-blue-500 mx-auto">
          Artificial Intelligence, Mobile Development, Web Development
        </p>
        <h1 className="text-center text-white text-2xl md:text-5xl font-bold uppercase leading-9">Data Repo Delivers Custom <br /> Software Solutions For The Best Of <br /> Your Business: GoodFirms</h1>

        <div className='flex justify-center items-ceter gap-4 mt-10'>
          <div className="flex items-center gap-2 text-white">
            <FontAwesomeIcon icon={faClock} className="w-4" />
            <p className="blog-publish-date">24/03/2019</p>
          </div>

          <div className="flex items-center gap-2 text-white">
            <FontAwesomeIcon icon={faComment} className="w-4" />
            <p className="blog-publish-date">0 Comments</p>
          </div>

          <div className="flex items-center gap-2 text-white">
            <FontAwesomeIcon icon={faBookmark} />
            <p className="blog-publish-date">950 Views</p>
          </div>

        </div>

      </section>

      <section className="body--container w-full px-4 py-8 md:py-10 md:px-12 lg:px-16 xl:px-20 2xl:px-24">
        <div className="mx-auto max-w-screen-md">
          <p className='pb-4 text-justify leading-7'>Advanced software solutions help distinguish your business from competitors and make it more competitive. It improves your clients’ experiences, brings more innovative and feature-rich products to the market, and makes your business and marketing setup more efficient, productive, and safe.</p>

          <img src="../images/blog-custom-solution.jpg" alt="" className='mx-auto mt-3 mb-6 w-full' />

          <p className='pb-4 text-justify leading-7'>Data Repo Software Solutions advances your products to market with custom software solutions. It is a trusted web and mobile software development company that was established in 2018 and has offices in the USA.</p>

          <p className='pb-4 text-justify leading-7'>As a trusted IT partner, Data Repo Software Solutions helps solve the digital challenges of your business. Company professionals do not just write the code. They help you develop your startup MVP from scratch or apply digital transformation to your already established business. Every client business is studied and integrated well into the IT department. A team constantly maintains and improves even after a project is completed.</p>

          <p className='pb-4 text-justify leading-7'>“We craft amazing web and mobile software solutions according to your business. We consult and advise you about the best uses of information technology to achieve your business objectives while extending your development needs and becoming a part of your team,” as shared by the CEO of Data Repo Software Solutions.</p>

          <p className='pb-4 text-justify leading-7'>Data Repo Software Solutions comprises a unique mix of creative and innovative people. The company’s project managers, software developers, and business analysts work together based on agile methodologies to design the perfect software solution. Agile solutions help the company’s professionals optimize their working processes and increase their development timeliness and efficiency.  The clients are frequently updated about the progress of the project, changes in requirements, and collaboration between the tech and business experts regarding the project. No micromanagement is followed; instead, the focus is given on creating & delivering simple, yet powerful software programs to the customers.</p>

          <p className='pb-4 text-justify leading-7'>Custom software development always gets you a unique and innovative product that scales your organization better. It improves your productivity and employee drive by integrating software with other programs. Your return on investment has nowhere to go in this way. In fact, you acquire ongoing technical support at every step during and after your product development.</p>

          <p className='pb-4 text-justify leading-7'>Data Repo Software Solutions brings you bespoke and tailor-made software solutions that help brands improve productivity, efficiency, consumer relations and ultimately increase revenue as the products are developed with specific goals and objectives in mind.</p>

          <p className=' text-justify leading-7'>GoodFirms – a leading review and rating firm, is globally acclaimed for its logical listings and sincere approach to guide businesses, software companies, and IT service providing firms. It recognizes Data Repo Software Solutions as soon-to-be top software development companies in the USA based on their market performance and recent market trends.</p>

          <h2 className='font-bold my-5' >About the Author</h2>
          <p className=' text-justify leading-7' >Working as a Content Writer at GoodFirms, Anna Stark bridges the gap between service seekers and service providers. Anna’s dominant role lingers to form every company’s achievement and critical attributes into words. She strongly believes in the charm of words and equips new approaches that work, always with concepts, something new to create, and something unique to enhance the firm’s identity.</p>
          <img
            src="../images/blog-custom-solution.jpg"
            alt="Author"
            className=" max-h-[100px] w-auto h-auto mt-5"
          />
        </div>

      </section>

      <section className="mb-8">
  <div className="max-w-screen-md mx-auto rounded-md flex justify-center items-center gap-6">
    <div className="flex flex-wrap gap-2">
      <p className="bg-gray-200 text-gray-600 px-2 py-1 rounded-md">Web Development</p>
      <p className="bg-gray-200 text-gray-600 px-2 py-1 rounded-md">AI</p>
      <p className="bg-gray-200 text-gray-600 px-2 py-1 rounded-md">Web Development</p>
      <p className="bg-gray-200 text-gray-600 px-2 py-1 rounded-md">AI</p>
      <p className="bg-gray-200 text-gray-600 px-2 py-1 rounded-md">Web Development</p>
      <p className="bg-gray-200 text-gray-600 px-2 py-1 rounded-md">AI</p>
    </div>
    <div className="flex items-center gap-3 ">
      <div className="flex items-center bg-[#42599E] p-4 rounded-lg text-white mb-2 max-h-1">
        <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faFacebook} /> share
        </a>
      </div>
      <div className="flex items-center bg-[#55ACEE] p-4 rounded-lg text-white mb-2 max-h-1">
        <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faTwitter} /> tweet
        </a>
      </div>
      <div className="flex items-center bg-[#34AF23] p-4 rounded-lg text-white mb-2 max-h-1">
        <a href="https://www.whatsapp.com" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faWhatsapp} /> whatsapp
        </a>
      </div>
      <div className="flex items-center bg-[#0798CF] p-4 rounded-lg text-white mb-2 max-h-1">
        <a href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLinkedin} /> linkedin
        </a>
      </div>
    </div>
  </div>
</section>
 
    </div>
  )
}

export default DataRepoCustomSolution;