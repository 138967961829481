import { faBookmark, faComment } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faFacebook, faTwitter, faWhatsapp, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const AiPoweredChatbots = () => {
  return (
    <div className="bg-[#fff] w-full flex flex-col">
      <section
        style={{ backgroundImage: `url('../images/ai-powered-bot.jpg')`, backgroundSize: "100% 60vh" }}
        className="hero--container relative flex flex-col justify-center w-full h-[60vh] bg-no-repeat bg-fixed md:bg-origin-border pt-[65px]">
        <p className="text-white p-1.5 rounded-md text-xs font-bold text-center mb-10 bg-blue-500 mx-auto">
          AI, Customer Experience
        </p>
        <h1 className="text-center text-white text-2xl md:text-5xl font-bold uppercase leading-9">
          AI-Powered Chatbots Enhancing Customer Experience
        </h1>

        <div className="flex justify-center items-center gap-4 mt-10">
          <div className="flex items-center gap-2 text-white">
            <FontAwesomeIcon icon={faClock} className="w-4" />
            <p className="blog-publish-date">24/01/2025</p>
          </div>

          <div className="flex items-center gap-2 text-white">
            <FontAwesomeIcon icon={faComment} className="w-4" />
            <p className="blog-publish-date">5 Comments</p>
          </div>

          <div className="flex items-center gap-2 text-white">
            <FontAwesomeIcon icon={faBookmark} />
            <p className="blog-publish-date">1500 Views</p>
          </div>
        </div>
      </section>

      <section className="body--container w-full px-4 py-8 md:py-10 md:px-12 lg:px-16 xl:px-20 2xl:px-24">
        <div className="mx-auto max-w-screen-md">
          <p className="pb-4 text-justify leading-7">
            AI-powered chatbots have revolutionized the way businesses interact with customers, offering instant, 24/7 assistance that significantly enhances customer satisfaction. Leveraging natural language processing (NLP) and machine learning, these intelligent systems can understand and respond to customer queries with remarkable accuracy and speed.
          </p>

          <img src="../images/ai-powered-bot.jpg" alt="" className="mx-auto mt-3 mb-6 w-full" />

          <p className="pb-4 text-justify leading-7">
            One of the key benefits of AI chatbots is their ability to provide personalized experiences. By analyzing customer behavior and preferences, chatbots can tailor recommendations, ensuring a more engaging and relevant interaction. This level of personalization not only improves customer satisfaction but also boosts brand loyalty.
          </p>

          <p className="pb-4 text-justify leading-7">
            Furthermore, chatbots reduce response times by handling multiple queries simultaneously, unlike traditional customer support methods. This efficiency ensures that customers receive prompt answers, improving their overall experience and reducing frustration.
          </p>

          <p className="pb-4 text-justify leading-7">
            However, implementing chatbots is not without challenges. Businesses must ensure that the AI is adequately trained to handle diverse queries and adapt to cultural nuances. Additionally, maintaining data security and privacy is crucial, as chatbots often handle sensitive customer information.
          </p>

          <p className="pb-4 text-justify leading-7">
            AI-powered chatbots are a cost-effective solution for businesses. They reduce the reliance on human agents for repetitive tasks, allowing support teams to focus on more complex customer needs. This balance between automation and human intervention creates an optimal customer support ecosystem.
          </p>

          <p className="pb-4 text-justify leading-7">
            Overall, AI-powered chatbots represent a transformative technology in customer experience management. By addressing potential challenges and continuously improving their capabilities, businesses can harness the full potential of chatbots to deliver exceptional customer service and drive long-term success.
          </p>
        </div>
      </section>

      <section className="mb-8">
        <div className="max-w-screen-md mx-auto rounded-md flex justify-between items-center gap-6">
          <div className="flex flex-wrap gap-2">
            <p className="bg-gray-200 text-gray-600 px-2 py-1 rounded-md">AI</p>
            <p className="bg-gray-200 text-gray-600 px-2 py-1 rounded-md">Customer Experience</p>
          </div>
          <div className="flex items-center gap-3">
            <div className="flex items-center bg-[#42599E] p-4 rounded-lg text-white mb-2 max-h-1">
              <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faFacebook} /> share
              </a>
            </div>
            <div className="flex items-center bg-[#55ACEE] p-4 rounded-lg text-white mb-2 max-h-1">
              <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faTwitter} /> tweet
              </a>
            </div>
            <div className="flex items-center bg-[#34AF23] p-4 rounded-lg text-white mb-2 max-h-1">
              <a href="https://www.whatsapp.com" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faWhatsapp} /> whatsapp
              </a>
            </div>
            <div className="flex items-center bg-[#0798CF] p-4 rounded-lg text-white mb-2 max-h-1">
              <a href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faLinkedin} /> linkedin
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AiPoweredChatbots;