import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Footer from './components/Footer';
import Header from './components/Header';
import { ActiveNavBarProvider } from './context/ActiveNavBarProvider';
import Expertise from "./pages/expertise/Expertise";
import WebDevelopment from "./pages/expertise/webDevelopment/WebDevelopment";
import MobileDevelopment from "./pages/expertise/mobileDevelopment/MobileDevelopment";
import ArtificialIntelligenceExpertise from "./pages/expertise/artificialIntelligenceExpertise/ArtificialIntelligenceExpertise";
import Services from "./pages/services/Services";
import DevelopmentCenterServices from "./pages/services/developmentCenterServices/DevelopmentCenterServices";
import ExtendedTeamServices from "./pages/services/extendedTeamServices/ExtendedTeamServices";
import BusinessConsulting from "./pages/services/it_BusinessConsulting/BusinessConsulting";
import ContactUs from "./pages/contactUs/ContactUs";
import CaseStudies from "./pages/caseStudies/CaseStudies";
import Blog from "./pages/blog/Blog";
import WhyUs from "./pages/whyUs/WhyUs";
import PrivacyPolicy from "./pages/privacyPolicy/PrivacyPolicy";
import Team from "./pages/team/Team";
import Careers from "./pages/careers/Careers";
import Home from "./pages/home/Home";
import Product from "./pages/product/Product";
import RDRental from "./pages/product/RDRental";
import ChurchDirectory from "./pages/product/ChurchDirectory";
import POSSystem from "./pages/product/POSSystem";
import EventManagementSystem from "./pages/product/EventManagementSystem";
import DataRepoCustomSolution from "./pages/blog/DataRepoCustomSolution";
import NaturalLanguageProcessing from "./pages/expertise/artificialIntelligenceExpertise/NaturalLanguageProcessing";
import SentimentAnalysis from "./pages/expertise/artificialIntelligenceExpertise/SentimentAnalysis";
import ModelTraining from "./pages/expertise/artificialIntelligenceExpertise/ModelTraining";
import RdRental from "./pages/caseStudies/RDRental";
import Churchdirectory from "./pages/caseStudies/ChurchDirectory";
import PosSystem from "./pages/caseStudies/POSSystem";
import EventManagementsystem from "./pages/caseStudies/EventManagementSystem";
import ScrollToTop from "./components/ScrollToTop";
import LTI from "./pages/blog/LTI";
import NLP from "./pages/blog/NLPText";
import DeveloperSyndrome from "./pages/blog/DeveloperSyndrome";
import DataRepoMostRecommended from "./pages/blog/DataRepoMostRecommended";
import DataRepoSoftwareRewind from "./pages/blog/DataRepoSoftwareRewind";
import ChatgptReplaceHuman from "./pages/blog/ChatgptReplaceHuman";
import CeclModel from "./pages/blog/CeclModel";
import DatasetCollectionForAi from "./pages/blog/DatasetCollectionForAi";
import JjKit from "./pages/product/JjKit";
import JjTimesheet from "./pages/product/JjTimesheet";
import AiPoweredChatbots from "./pages/blog/AiPoweredChatbots";
import AiCybersecurity from "./pages/blog/AiCybersecurity";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <ActiveNavBarProvider>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/product" element={<Product />} />
          <Route path="/rd-rental" element={<RDRental />} />
          <Route path="/church-directory" element={<ChurchDirectory />} />
          <Route path="/pos-system" element={<POSSystem />} />
          <Route path="/event-management-system" element={<EventManagementSystem />} />
          <Route path="/jj-kit" element={<JjKit />} />
          <Route path="/jj-timesheet" element={<JjTimesheet />} />
          <Route path="/expertise" element={<Expertise />} />
          <Route path="/web-development" element={<WebDevelopment />} />
          <Route path="/mobile-development" element={<MobileDevelopment />} />
          <Route path="/artificial-intelligence-expertise" element={<ArtificialIntelligenceExpertise />} />
          <Route path="/services" element={<Services />} />
          <Route path="/it-business-consulting" element={<BusinessConsulting />} />
          <Route path="/development-center-services" element={<DevelopmentCenterServices />} />
          <Route path="/extended-team-services" element={<ExtendedTeamServices />} />
          <Route path="/case-studies" element={<CaseStudies />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/data-repo-custom-solution" element={<DataRepoCustomSolution />} />
          <Route path="/blog/lti-integration-for-lms-systems" element={<LTI />} />
          <Route path="/blog/nlp-text-classification" element={<NLP />} />
          <Route path="/blog/developer-syndrome" element={<DeveloperSyndrome />} />
          <Route path="/blog/data-repo-most-recommended-company" element={<DataRepoMostRecommended />} />
          <Route path="/blog/data-repo-software-clutch-rewind" element={<DataRepoSoftwareRewind />} />
          <Route path="/blog/can-chatgpt-replace-human-work" element={<ChatgptReplaceHuman />} />
          <Route path="/blog/cecl-model-risk-application" element={<CeclModel />} />
          <Route path="/blog/new-datasets-to-train-ai" element={<DatasetCollectionForAi />} />
          <Route path="/blog/ai-powered-chatbots" element={<AiPoweredChatbots />} />
          <Route path="/blog/ai-cybersecurity" element={<AiCybersecurity />} />


          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/why-us" element={<WhyUs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/team" element={<Team />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/natural-language-processing" element={<NaturalLanguageProcessing />} />
          <Route path="/sentiment-analysis" element={<SentimentAnalysis />} />
          <Route path="/model-training" element={<ModelTraining />} />
          <Route path="/case-studies/rd-rental" element={<RdRental />} />
          <Route path="/case-studies/church-directory" element={<Churchdirectory />} />
          <Route path="/case-studies/pos-system" element={<PosSystem />} />
          <Route path="/case-studies/event-management-system" element={<EventManagementsystem />} />
        </Routes>
        <Footer />
      </ActiveNavBarProvider>
    </Router>
  );
}

export default App;
