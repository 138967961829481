import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { useState } from 'react';

const blogData = [
  {
    id: 1,
    img: '../images/custom-software-solutions.png',
    //img src: canva
    category: 'Artificial Intelligence',
    link: '/blog/data-repo-custom-solution',
    title: 'Data Repo Delivers Custom Software Solutions for the Best of Your Business: GoodFirms',
    description: 'Advanced software solutions help distinguish your business from competitors and make it more competitive.',
    publishDate: '12/09/2021',
    author: 'Amit Singh',
  },
  {
    id: 2,
    img: '../images/lms-systems.png',
    //img src: canva
    category: 'Education Technology',
    link: '/blog/lti-integration-for-lms-systems',
    title: 'LTI Integration for LMS systems',
    description: 'LTI (Learning Tools Interoperability) is a standard developed by IMS Global Learning Consortium that allows educational systems and tools to communicate and exchange data with each other. It is widely used in the education industry to enable a seamless integration of third-party learning applications, such as online homework systems,',
    publishDate: '01/07/2023',
    author: 'Amit Singh',
  },
  {
    id: 3,
    img: '../images/nlp.png',
    //img src: canva
    category: 'Artificial Intelligence',
    link: '/blog/nlp-text-classification',
    title: 'What is NLP Text Classification?',
    description: 'Discover what NLP Text Classification is, the main steps to follow, and principal challenges to confront. Tips & examples included!',
    publishDate: '2022-10-9',
    author: 'Prashant Singh',
  },
  {
    id: 4,
    img: '../images/software-developer.png',
    //img src: canva
    category: 'Software trends',
    link: '/blog/developer-syndrome',
    title: 'Overengineering: The Developer’s Syndrome',
    description: 'Overengineering is the act of getting carried away by technology trends without a rational justification, making software more complex, slow, and inefficient. Do you have this "developer syndrome"? Discover how to avoid it!',
    publishDate: '2020-7-15',
    author: 'Amit Singh',
  },
  {
    id: 5,
    img: '../images/software-development-company.png',
    //img src: canva
    category: 'Artificial Intelligence',
    link: '/blog/data-repo-most-recommended-company',
    title: 'Data Repo Lauded as One of the Most Recommended Companies',
    description: 'We have seen as well as engineered a good number of breakthroughs in our time. However, it still astounds us to discover that as we have aided businesses to grow, our own has significantly flourished meanwhile.  When you’re truly dedicated to the success of your client, it’s easy to',
    publishDate: '2022-4-10',
    author: 'Prashant Singh',
  },
  {
    id: 6,
    img: '../images/data-repo-software-clutch.png',
    //img src: canva
    category: 'Artificial Intelligence',
    link: '/blog/data-repo-software-clutch-rewind',
    title: 'Data Repo Software’s 2021 Clutch Rewind',
    description: 'Data Repo Software is a digital development solution provider that focuses on strategic experience creation. Founded in 2014, we’ve been helping SMEs tackle their development challenges by utilizing modern technologies and industry best practices. Today, we’re a trusted delivery partner of cost-effective solutions to many companies. In 2021, we’ve',
    publishDate: '2022-1-31',
    author: 'Amit Singh',
  },
  {
    id: 7,
    img: '../images/chatgpt-replace-human-work.png',
    //img src: canva
    category: 'Artificial Intelligence',
    link: '/blog/can-chatgpt-replace-human-work',
    title: 'Can ChatGPT Replace Human Work?',
    description: 'AI, including chatbot technology like ChatGPT, has the potential to automate certain tasks and improve efficiency in various industries. However, it is important to note that AI is not meant to replace human work entirely, but rather to augment and assist it. In certain industries, such as customer service',
    publishDate: '2023-03-15',
    author: 'Prashant Singh',
  },
  {
    id: 8,
    img: '../images/online-banking.png',
    //img src: canva
    category: 'Banking',
    link: '/blog/cecl-model-risk-application',
    title: 'CECL Model Risk Application',
    description: 'The Current Expected Credit Loss (CECL) model is a new accounting standard for the financial industry that aims to provide better insight into the credit risk of a bank’s portfolio. The CECL model has been designed to replace the traditional incurred loss model with a forward-looking approach that is',
    publishDate: '2023-04-25',
    author: 'Prashant Singh',
  },
  {
    id: 9,
    img: '../images/training-ai-models.png',
    //img src: canva
    category: 'Artificial Intelligence',
    link: '/blog/new-datasets-to-train-ai',
    title: 'Collecting News Datasets and training AI Models',
    description: 'Collecting a News Dataset with Various Categories and Using Machine Learning to Train a Model The world is inundated with an overwhelming amount of information, and it can be difficult to keep up with the latest news and events. However, with the help of machine learning algorithms, we can',
    publishDate: '2023-09-09',
    author: 'Prashant Singh',
  },
  {
    id: 10,
    img: '../images/ai-powered-bot.jpg',
    //img src: pexels.com
    category: 'Artificial Intelligence',
    link: '/blog/ai-powered-chatbots',
    title: 'AI-Powered Chatbots Enhancing Customer Experience',
    description: 'AI-powered chatbots have revolutionized the way businesses interact with customers, offering instant, 24/7 assistance that significantly enhances customer satisfaction. Leveraging natural language processing (NLP) and machine learning, these intelligent systems can understand and respond to customer queries with remarkable accuracy and speed.',
    publishDate: '2025-01-24',
    author: 'Udit Garg'
  },
  {
    id: 11,
    img: '../images/ai-cybersecurity.jpg',
    //img src: pexels.com
    category: 'Artificial Intelligence',
    link: '/blog/ai-cybersecurity',
    title: 'The Role of AI in Cybersecurity',
    description: 'Cybersecurity has become one of the most critical concerns for individuals and organizations alike, given the rise in sophisticated cyber threats. Artificial intelligence (AI) has emerged as a game-changing solution, enabling more effective threat detection and prevention.',
    publishDate: '2025-01-24',
    author: 'Udit Garg'
  },
];

const Blog = () => {
  const cardsPerPage = 6;
  const [currentPage, setCurrentPage] = useState(1);

  const sortedBlogData = [...blogData].sort((a, b) => b.id - a.id);
  const totalPages = Math.ceil(sortedBlogData.length / cardsPerPage);

  const startIndex = (currentPage - 1) * cardsPerPage;
  const endIndex = startIndex + cardsPerPage;
  const currentBlogData = sortedBlogData.slice(startIndex, endIndex);

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="bg-[#e9edff] w-full flex flex-col">
      <section
        style={{
          backgroundImage: "url('https://images.unsplash.com/photo-1434030216411-0b793f4b4173?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
          backgroundSize: '100% 80vh',
        }}
        className="hero--container relative flex flex-col justify-center w-full h-[60vh] bg-no-repeat bg-fixed md:bg-origin-border pt-[65px]"
      >
        <h1 className="text-center text-white text-2xl md:text-5xl font-bold uppercase">Blog</h1>
        <div className="row--image-style bg-transparent w-full absolute bottom-0 left-0 right-0">
          <svg
            className="waves w-full h-44"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1000 300"
            preserveAspectRatio="none"
          >
            <path
              fill="rgb(226 232 240)"
              d="M 1000 280 l 2 -253 c -155 -36 -310 135 -415 164 c -102.64 28.35 -149 -32 -235 -31 c -80 1 -142 53 -229 80 c -65.54 20.34 -101 15 -126 11.61 v 54.39 z"
            ></path>
            <path
              fill="rgb(100 116 139)"
              d="M 1000 261 l 2 -222 c -157 -43 -312 144 -405 178 c -101.11 33.38 -159 -47 -242 -46 c -80 1 -153.09 54.07 -229 87 c -65.21 25.59 -104.07 16.72 -126 16.61 v 22.39 z"
            ></path>
            <path
              fill="#E5E7EB"
              d="M 1000 300 l 1 -230.29 c -217 -12.71 -300.47 129.15 -404 156.29 c -103 27 -174 -30 -257 -29 c -80 1 -130.09 37.07 -214 70 c -61.23 24 -108 15.61 -126 10.61 v 22.39 z"
            ></path>
          </svg>
        </div>
      </section>

      <section className="body--container w-full px-4 py-8 md:py-10 md:px-12 lg:px-16 xl:px-20 2xl:px-24">
        <div className="mx-auto max-w-screen-xl background grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {currentBlogData.map((item) => (
            <div
              key={item.id}
              className="blog-card bg-white rounded-md p-4 relative group hover:shadow-lg"
            >
              <div className="blog-image-category relative">
                <img
                  src={item.img}
                  alt={item.title}
                  className="blog-image rounded-md transform transition-transform hover:scale-105"
                />
                <p className="absolute bottom-0 left-0 m-3 bg-blue-600 text-white p-1.5 rounded-md text-xs font-bold">
                  {item.category}
                </p>
              </div>
              <div className="blog-details">
                <Link to={item.link} className="blog-title-link">
                  <h2 className="blog-title text-2xl md:text-2xl my-3 font-bold hover:text-blue-500 transition-colors cursor-pointer">
                    {item.title}
                  </h2>
                </Link>
                <p className="blog-description mb-3">{item.description}</p>
                <div className="flex items-center justify-between">
                  <div className="flex flex-col items-start space-y-2">
                    <div className="flex items-center space-x-2 text-gray-700">
                      <FontAwesomeIcon icon={faClock} className="w-4 h-4" />
                      <p className="text-sm">{item.publishDate}</p>
                    </div>
                    {item.author && (
                      <p className="text-sm text-gray-700 font-medium">
                        <span className="text-gray-700">By:</span> {item.author}
                      </p>
                    )}
                  </div>
                  <Link
                    to={item.link}
                    className="w-12 h-12 p-4 rounded-full bg-transparent flex flex-col justify-center items-center border-2 hover:text-blue-500 hover:border-blue-500"
                    onClick={() => { }}
                  >
                    <FontAwesomeIcon icon={faArrowRight} />
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="body--container w-full px-4 pb-10 md:pb-12 md:px-12 lg:px-16 xl:px-20 2xl:px-24">
        <div className="pagination-buttons max-w-screen-xl flex justify-between px-4 py-2 mx-auto">
          <button
            onClick={goToPreviousPage}
            disabled={currentPage === 1}
            className="text-blue-500 font-semibold hover:text-blue-700"
          >
            <FontAwesomeIcon icon={faArrowLeft} /> <span>Previous Page</span>
          </button>
          <button
            onClick={goToNextPage}
            disabled={currentPage === totalPages}
            className="text-blue-500 font-semibold hover:text-blue-700"
          >
            Next Page <span><FontAwesomeIcon icon={faArrowRight} /></span>
          </button>
        </div>
      </section>

      <section
        style={{ backgroundImage: `url('../images/body-footer.jpg')`, backgroundSize: "100% 100%" }}
        className="hero--container flex flex-col justify-center items-center gap-4 w-full h-full bg-no-repeat md:bg-origin-border pt-40 pb-20 py-8 md:py-12 lg:py-16 xl:py-20 2xl:py-24">
        <h1 className="text-center text-white text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl font-bold">Our team is ready to boost your business</h1>
        <p className="text-center text-white text-sm sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl font-semibold">“Tailored. Flexible. Competitive.”</p>
        <button className="w-fit bg-slate-500 hover:bg-slate-700 text-white font-semibold py-2 px-8 rounded uppercase">Get in touch</button>
      </section>
    </div>
  );
};

export default Blog;